import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import Grid from '@material-ui/core/Grid';
import ActionPactLogo from '../../images/brands/logo-actionpact.png';
import ReturnArrow from '../../images/return-arrow.svg';

const ActionPact = () => (
  <Layout>
    <Grid container
          className="link-list"
    >
      <Grid item xs={4}>
        <div>
          <Link to="/projects/"><img src={ ReturnArrow } className="return-arrow" width='30px' alt="Return Logo" /></Link>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div>
          <Link to="/about/">About</Link>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div>
          <Link to="/contact/">Contact</Link>
        </div>
      </Grid>
    </Grid>
      <Grid container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={8}>
        <div className='app-intro'>
          <Link to="projects/">Projects</Link>
          <Grid item xs={12}>
                 <span>
              <Link className="arrow" to="/projects/cafethijssen">&#x2039; </Link>
            </span>
            <h2 className="inline"> Actionpact </h2>
            <span >
              <Link className="arrow" to="/projects/vodafoneziggo">&#x203A;</Link>
            </span>
          </Grid>
          <img src={ ActionPactLogo } className="project-detail"  alt="Skills" />
          <Grid container
                direction="row"
                justify="center"
                className="about-content">
            <Grid item md={6}>
              <h3>Summary</h3>
              <p>Implement redesign. Added some new functionalities for easy templating with handlebars.js</p>
            </Grid>
            <Grid item md={6}>
              <h3>Stack</h3>
              <p className="subcontent">Bootstrap3, Sass, Jquery and Handlebars.js</p>

              <h3>Period</h3>
              <p className="subcontent">2014</p>
              <h3>URL</h3>
              <a className="white" href='http://actionpact.nl' rel="noopener noreferrer"  target="_blank" alt="actionpact"> <h4>Actionpact.nl </h4></a>

            </Grid>
          </Grid>
        </div>
      </Grid>
  </Layout>
)

export default ActionPact;
